<template>
    <div class="page formPage" id="exhibitionAdd">
        <div class="biaoti">{{ distinguish==2 ? '展厅新增' : '展厅编辑'}}</div>
        <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-width="100px"
                 class="demo-ruleForm">
            <el-row>
                <el-col :span="12">
                    <el-col :span="20">
                        <el-form-item label="展厅名称" prop="exhibitionName">
                            <el-input maxlength="50"
                                      placeholder="请输入展厅名称(限50字)" :disabled="!distinguish"
                                      v-model="ruleForm.exhibitionName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col style="position: relative" :span="20">
                        <el-form-item label="所属展览" prop="exhibitionManagementId">
                            <el-select style="width: 100%" v-model="ruleForm.exhibitionManagementId" placeholder="请选择">
                                <el-option
                                        v-for="item in theFareList"
                                        :key="item.id"
                                        :label="item.exhibitionName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div style="margin-left:100px;position: absolute;bottom: -21px;font-size: 10px;">如展览已绑定藏品，保存后不支持修改所属展览。除非展览解绑文物（藏品位置中解绑）或所属展览已结束。</div>
                    </el-col>
                    <el-col style="margin-top: 35px" :span="20">
                        <el-form-item label="排序" prop="serialNumber">
                            <el-input-number style="width: 100%" v-model="ruleForm.serialNumber" :disabled="!distinguish" @blur="handleSerialNumber" :min="1" :max="999" label="请输入序号（1~999 ）序号越小显示越靠前"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="12">
                    <el-col :span="20" :offset="4">
                        <el-form-item label="位置" prop="exhibitionLocation">
                            <el-input maxlength="50"
                                      placeholder="请输入展厅名称(限50字)" :disabled="!distinguish"
                                      v-model="ruleForm.exhibitionLocation"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20" :offset="4">
                        <el-form-item label="启用状态" prop="enabledState">
                            <el-select :disabled="!distinguish" style="width: 100%" v-model="ruleForm.enabledState"
                                       placeholder="请选择">
                                <el-option
                                        v-for="item in tyoeList"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>
            <el-row style="margin-top: 24px">
                <el-col>
                    <el-form-item label="大图" prop="banner">
                        <el-button type="primary" :disabled="ruleForm.banner.length == 1" size="small"
                                   @click="upDate(1)">上传图片
                        </el-button>
                        <div class="picture-format">图片尺寸：750*900；支持格式：PNG、BMP、JPG、JPEG、GIF；单张图片限10M，限1张照片</div>
                        <div class="flex_l_c">
                            <div class="zhanshi" v-for="item in ruleForm.banner" :key="item.imgUrl">
                                <el-image
                                        style="width: 200px;"
                                        :src="item.imgUrl"
                                        :fit="'contain'"></el-image>
                                <div @click="deletesshuj(item,1)" class="el-icon-error sheZhi"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="小图" prop="img">
                        <el-button type="primary" :disabled="ruleForm.img.length == 5" size="small" @click="upDate(2)">
                            上传图片
                        </el-button>
                        <div class="picture-format">图片尺寸：750*480；支持格式：PNG、BMP、JPG、JPEG、GIF；单张图片限10M；限5张图片。</div>
                        <div class="flex_l_c">
                            <div class="zhanshi" v-for="item in ruleForm.img" :key="item.imgUrl">
                                <el-image
                                        style="width: 200px;"
                                        :src="item.imgUrl"
                                        :fit="'contain'"></el-image>
                                <div @click="deletesshuj(item,2)" class="el-icon-error sheZhi"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="简介" prop="introduction">
                        <Editor :disMenus="'video'" :editortext="ruleForm.introduction" ref="editor"
                                @changeHtml="noticeGetEditor"></Editor>
                    </el-form-item>
                </el-col>
            </el-row>
            <div style="text-align: center">
                <el-button :loading="tiJioaLoading" size="small" v-if="distinguish == 1" type="primary"
                           @click="submitForm('ruleForm',0)">
                    保存
                </el-button>
                <el-button :loading="tiJioaLoading" size="small" v-if="distinguish == 2" type="primary"
                           @click="submitForm('ruleForm',1)">
                    提交
                </el-button>
                <el-button size="small" @click="returnSuperior()">
                    取消
                </el-button>
            </div>
        </el-form>
        <uploadPopup ref="uploadpopup" :fileList="fileList" :allowFileNumber="allowFileNumber"
                     :allowFileSize="allowFileSize" :allowFiles="allowFiles" @updatalist=receiveList></uploadPopup>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本组件
    import uploadPopup from './uploadPopup'

    export default {
        name: "exhibitionAdd",
        components: {Editor, uploadPopup},
        data() {
            return {
                ruleForm: {
                    exhibitionName: '',
                    exhibitionLocation: '', //展厅位置
                    exhibitionManagementId: '', //所属展览
                    enabledState: '',
                    introduction: '',
                    img: [],
                    banner: [],
                    // videoList: [],
                    guidedExhibitionFiles: [],
                    serialNumber:1,
                },
                tiJioaLoading: false,
                rules: {
                    exhibitionName: [
                        {required: true, message: '请输入展厅名称', trigger: 'blur'},
                    ],
                    exhibitionManagementId: [
                        {
                            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '请输入正确的价格'
                        },
                    ],
                    img: [
                        {required: true, message: '请至少上传一张图片', trigger: 'change'},
                    ],
                    banner: [
                        {required: true, message: '请至少上传一张图片', trigger: 'change'},
                    ],
                    enabledState: [
                        {required: true, message: '请选择启用状态', trigger: 'change'},
                    ],
                    serialNumber: [
                        {required: true, message: '请填写排序', trigger: 'change'},
                    ],
                },
                tyoeList: [
                    {
                        label: '启用',
                        id: '1',
                    },
                    {
                        label: '禁用',
                        id: '0',
                    },
                ],
                allowFiles: [],//上传文件类型
                allowFileSize: 10,//单个最大 MB
                allowFileNumber: 10,//上传数量
                fileList: [],//上传历史
                distinguish: 2,
                upDateNumber: '',//哪个上传
                theFareList: [], //展览列表
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                if (this.$route.query.num) {
                    this.distinguish = this.$route.query.num;
                }
                if (this.$route.query.id && this.$route.query.id != 0) {
                    //获取文件详情
                    this.$axios(this.api.exhibitionHall.getById + this.$route.query.id, {}, 'get').then(res => {
                        this.ruleForm = res.data;
                        this.ruleForm.exhibitionName = res.data.exhibitionName
                        this.ruleForm.exhibitionLocation = res.data.exhibitionLocation
                        this.ruleForm.exhibitionManagementId = res.data.exhibitionManagementName
                        this.ruleForm.introduction = res.data.introduction
                        this.$set(this.ruleForm, 'img', res.data.guidedImgFiles)
                        this.$set(this.ruleForm, 'banner', res.data.guidedBannerList)
                        this.ruleForm.enabledState = res.data.enabledState;
                    })
                }
                this.$axios(this.api.exhibitionHall.queryAll, {}, 'get').then(res => {
                    if (res.status) {
                        this.theFareList = res.data;
                    }
                })
            },

            // 获取富文本数据(报名须知)
            noticeGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.introduction = data;
            },

            //获取音频播放时长
            getAudioDate(url) {
                let audio = document.createElement('audio')
                audio.src = url;
                return new Promise((resolve, reject) => {
                    audio.addEventListener('canplay', function () {
                        console.log(audio.duration)
                        resolve({
                            data: audio.duration,
                        })
                    })
                })
            },

            //接受上传数据
            receiveList(arry) {
                console.log(arry)
                if (this.upDateNumber == 1) {
                    arry[0].forEach(item => {
                        item.fileType = 2
                        item.type = 2
                    })
                    this.ruleForm.banner = arry[0];
                } else {
                    arry[0].forEach(item => {
                        item.fileType = 1
                        item.type = 2
                    })
                    this.ruleForm.img = arry[0];
                    console.log(this.ruleForm.img)
                }
            },

            //排序改变
            handleSerialNumber(price){
                if (!this.ruleForm.serialNumber){
                    this.ruleForm.serialNumber = 1;
                }
            },

            //上传 num1为图片上传 2音频上传
            upDate(num) {
                this.upDateNumber = num
                this.$refs.uploadpopup.uploadPath = 'guided';
                if (num == 1) {
                    this.$refs.uploadpopup.resolvingPower = '750*900';
                    this.allowFiles = ['png', 'bmp', 'jpg', 'jpeg', 'gif'];
                    this.fileList = this.ruleForm.banner;
                    this.allowFileSize = 10;
                    this.allowFileNumber = 1;
                } else {
                    this.$refs.uploadpopup.resolvingPower = '750*480';
                    this.allowFiles = ['png', 'bmp', 'jpg', 'jpeg', 'gif'];
                    this.fileList = this.ruleForm.img;
                    this.allowFileSize = 10;
                    this.allowFileNumber = 5;
                }
                this.$refs.uploadpopup.numb = 1;
                this.$refs.uploadpopup.dialogVisible = true;
                this.$refs.uploadpopup.uploading = true;
            },

            //删除
            deletesshuj(row, num) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (num == 1) {
                        this.ruleForm.banner = this.ruleForm.banner.filter(item => item.imgUrl != row.imgUrl)
                    } else {
                        this.ruleForm.img = this.ruleForm.img.filter(item => item.imgUrl != row.imgUrl)
                    }
                })
            },

            //设为主显 num 1为图片 2为音频
            mainDisplay(address, num) {
                if (num == 1) {
                    this.ruleForm.photoList.forEach(item => {
                        if (item.imgUrl === address) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                } else {
                    this.ruleForm.videoList.forEach(item => {
                        if (item.imgUrl == address) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                }
                console.log(this.ruleForm.photoList)
            },

            //num 0保存，1新增
            submitForm(formName, num) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.exhibitionName.trim() == '') {
                            this.$message.error('请输入展厅名称')
                            return
                        }
                        this.tiJioaLoading = true
                        this.ruleForm.guidedExhibitionFiles = [...this.ruleForm.banner, ...this.ruleForm.img]
                        if (num == 0) { //保存
                            this.$axios(this.api.exhibitionHall.updateById, {...this.ruleForm}, 'put').then(res => {
                                console.log(res);
                                if (res.status) {
                                    this.$message.success('保存成功')
                                    this.returnSuperior();
                                } else {
                                    this.$message.error(res.msg);
                                }
                                this.tiJioaLoading = false
                            })
                        } else { //新增
                            this.$axios(this.api.exhibitionHall.save, {...this.ruleForm}, 'post').then(res => {
                                console.log(res);
                                if (res.status) {
                                    this.$message.success('新增成功')
                                    this.returnSuperior();
                                } else {
                                    this.$message.error(res.msg);
                                }
                                this.tiJioaLoading = false
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //返回展厅列表
            returnSuperior() {
                let pageNo = this.$route.query.pageNo
                let culturalName = this.$route.query.culturalName;
                this.$router.push({
                    path: '/exhibitionManagement/exhibition/exhibition',
                    query: {
                        pageNo: pageNo,
                        culturalName: culturalName,
                    }
                })
            },

        },
    }
</script>

<style scoped>
    .picture-format {
        font-size: 12px;
    }

    .biaoti {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .sheZhi {
        position: absolute;
        font-size: 18px;
        color: #7F7F7F;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
    }

    .zhanshi {
        padding: 10px;
    }
</style>